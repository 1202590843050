<template>
  <div class="box">
    <!-- 首页的log区域 -->
    <div class="companyLog" @click="btnClickJump({
          name: '首页',
          id: '0',
          routerPath: '/'})">
      <img src="../../assets/homePageImg/bar_logo@2x.png" alt="" />
    </div>
    <!-- 导航条区域 -->
    <div class="navbar">
      <div v-for="(item) in navTitle" :key="item.id"
           class="text">
        <div
          :class="{ navindex: activeIndex == item.id ? true : false }"
          @click="btnClickJump(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemIndex: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentPage: '/',
      activeIndex: -1,
      navTitle: [
        {
          name: '首页',
          id: '0',
          routerPath: '/',
        },
        {
          name: '解决方案',
          id: '1',
          routerPath: '/solution',
        },
        {
          name: '平台服务',
          id: '2',
          routerPath: '/comprehensive',
        },
        {
          name: '资金系统',
          id: '7',
          routerPath: '/transaction',
        },
        {
          name: '产融平台',
          id: '3',
          routerPath: '/financialRegion',
        },
        {
          name: '支持中心',
          id: '4',
          routerPath: '/mechanism',
        },
        {
          name: '合作伙伴',
          id: '5',
          type: 'link',
          routerPath: '/partner',
        },
        {
          name: '关于我们',
          id: '6',
          type: 'link',
          routerPath: '/about',
        },
        // {
        //   name: '交易平台',
        //   id: '7',
        //   type: 'link',
        //   routerPath: '/transaction',
        // },
        // {
        //   name: '定价引擎',
        //   id: '8',
        //   type: 'link',
        //   routerPath: '/pricingEngine',
        // },
        // {
        //   name: '管理系统',
        //   id: '9',
        //   type: 'link',
        //   routerPath: '/manageSystem',
        // },
      ]
    }
  },
  mounted() {
    let hash = this.$route.query.hash;
    console.log('this.itemIndex',this.itemIndex)
    if (this.$route.path === '/') {
      this.activeIndex = 0
      if (hash || hash == 0) {
        this.anchor('index_' + hash)
        this.activeIndex = hash
      }
    } else if (this.$route.path === '/about') {
      this.activeIndex = 6
    } else{
      this.activeIndex = this.itemIndex
    }
  },
  methods: {
    btnClickJump (item) {
      this.activeIndex = item.id
      if (item.type === 'link'){
        this.$router.push({
          path: `${item.routerPath}`
        })
      } else {
        if(this.$route.path !== '/'){
          this.$router.push({
            path: '/',
            query: {
              hash: item.id
            }
          })
        } else {
          this.anchor('index_' + item.id)
        }
      }
    },
    /*锚链接跳转*/
    anchor(anchorName) {
      /*找到锚点*/
      let anchorElement = document.getElementById(anchorName);
      anchorElement.scrollIntoView();
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      window.scrollTo(0, scrollTop - 68)

    },
  }

}
</script>
<style lang="scss" scoped>
.box {
  // width: 1180px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  .companyLog {
    width: 181px;
    height: 44px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .navbar {
    margin-right: -25px;
    .text {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      width: 96px;
      text-align: center;
      color: #333333;
      div{
        display: inline;
        cursor: pointer;
      }
    }

    .text:first-of-type {
      width: 72px;
    }
    .navindex {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
  }
}
</style>
