<template>
  <div class="minBox">
    <!-- 顶部通用 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="boxContentBg">
      <div class="banner">
        <div class="bannerTitle">
          关于我们
        </div>
        <div class="seeMore">
          最懂金融的科技服务平台
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="boxContentProfile">
      <div class="companyProfile ">
        <div class="opportunityTitle">公司简介</div>
        <div class="companyProfileContent">
          <div class="comrocomTremLeft">
            <div>
              华炫鼎盛（北京）科技有限公司（原名“鼎盛(厦门)电脑系统有限公司”，以下简称“华炫鼎盛”）是金融行业专业的信息化解决方案供应商以及金融交易平台的服务提供商，是国内率先于资金业务领域应用区块链大数据与机器学习技术的高新企业，为银行、保险与大型企业财务公司提供资金业务的交易平台、风险管理系统以及集结算、核算、清算、头寸与流动性管理于一体的管理解决方案。
            </div>
            <br />
            <br />
            <div>
              近年来为响应中央促进金融服务制造强国建设的号召，华炫鼎盛应用人工智能技术打造产融合作平台，透过大数据、商业智能、企业画像、机器学习等手段，提供政策智能匹配、企业智能征信、风险智能定价、贷后智能追踪等服务，降低金融机构融资风险、提升金融机构放贷意愿、降低企业融资成本、协助企业走出融资难的困境，为金融服务制造强国建设尽一份心力。
            </div>
          </div>
          <div class="comrocomTremRight">
            <video
              data-v-47d753ab=""
              src="../../assets/aboutMy/vedio1.mp4"
              loop="loop"
              muted="muted"
              controls="controls"
              disablepictureinpicture="true"
              id="video"
              class="pingtaiVideo"
              style="height: auto;"
            ></video>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司成立于 -->
    <div class="boxContentAbout">
      <div class="AboutUs">
        <div class="AboutUsContent">
          <div>
               华炫鼎盛（北京）科技有限公司原名为“鼎盛（厦门）电脑系统有限公司”，于1996年4月创立，注册资本人民币 10000 万元，总部设在北京。
          </div>
          <div>
            公司的技术团队集聚了国内外金融IT领域专业人才，既有曾任跨国企业的核心骨干，也有多位具备15年以上实际经验的系统架构师与开发工程师，
          </div>
          <div>
            加上大批具有丰富行业经验的部署实施工程师，使得华炫鼎盛的产品不仅广受客户的好评，华炫鼎盛的服务也深获客户的赞赏。
          </div>
        </div>
        <div class="aboutsBg">
          <img src="../../assets/aboutMy/aboutus_intro2_yhbg.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="boxContentHistory">
      <div class="developmentHistory">
        <div class="opportunityTitle">发展历程</div>
        <div class="historyImage">
          <img src="../../assets/aboutMy/aboutus_develop.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 地图 -->
    <div class="boxContentMap">
      <div class="contentMap">
        <div class="opportunityTitle">联系我们</div>
        <div class="contentMapImage">
          <img src="../../assets/aboutMy/1_54.png" alt="" />
        </div>
        <div class="contactUs">
          <div class="contactItem">
            <div class="contactItemImg">
              <img src="../../assets/aboutMy/aboutus_email.png" alt="" />
            </div>
            <div class="contactItemEmail">
              <div class="itemEmail">服务邮箱</div>
              <div class="itemAdd">service@topsoft.com.cn</div>
            </div>
          </div>
          <div class="contactItem">
            <div class="contactItemImg">
              <img src="../../assets/aboutMy/aboutus_phone.png" alt="" />
            </div>
            <div class="contactItemEmail">
              <div class="itemEmail">客服电话</div>
              <div class="itemAdd">010-83020210</div>
            </div>
          </div>
          <div class="contactItem">
            <div class="contactItemImg">
              <img src="../../assets/aboutMy/aboutus_address.png" alt="" />
            </div>
            <div class="contactItemEmail">
              <div class="itemEmail">公司地址</div>
              <div class="itemAdd">
                北京市海淀区学院南路62号中关村资本大厦11层
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/public/NavBar'
import FooterAll from '@/components/public/footerAll'
export default {
  data() {
    return {
      activeIndex: '',
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created() {
    this.activeIndex = this.$route.query.id
  },
}
</script>
<style lang="scss" scoped>
@import '../platformServices/currency.scss';
.boxContentBg {
  width: 100%;
  background: url('../../assets/aboutMy/aboutus_banner.png') no-repeat center
    center !important;
}
.minBox {
  min-width: 1180px;
  width: 100%;
  background: #fbfbfb;
  > div > div {
    width: 1180px;
    margin: 0 auto;
  }
  .boxContent {
    background: #ffffff;
    .navbar {
      width: 1180px;
    }
  }
  .boxContentProfile {
    background: url('../../assets/aboutMy/aboutus_intro_bg.png') no-repeat
      center center;
    .companyProfile {
      @extend .currency;
      padding-bottom: 60px;
      .companyProfileContent {
        background: #ffffff;
        height: 372px;
        display: flex;
        align-items: center;
        .comrocomTremLeft {
          width: 547px;
          height: 288px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          padding: 54px 40px 30px 19px;
          color: rgba(0, 0, 0, 0.8);
        }
        .comrocomTremRight {
          padding-right: 20px;
          video {
            width: 554px;
          }
        }
      }
    }
  }
  .boxContentAbout {
    background: url('../../assets/aboutMy/aboutus_intro2_bg.png') no-repeat
      center center;
    .AboutUs {
      position: relative;
      // width: 1086px;
      height: 90px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      padding: 82px 0 68px 0;
      text-align: center;
      .AboutUsContent {
        width: 1086px;
        height: 90px;
        margin: 0 auto;
        // background: #000;
      }
      .aboutsBg {
        position: absolute;
        left: 30px;
        top: 40px;
        width: 100px;
        height: 100px;
        // background: #fff;
        @extend .imgFill;
      }
    }
  }
  .boxContentHistory {
    background: #ffffff;
    .developmentHistory {
      @extend .currency;
      .historyImage {
        width: 100%;
        height: 390px;
        padding-bottom: 31px;
        > img {
          @extend .imgFill;
        }
      }
    }
  }
  .boxContentMap {
    .contentMap {
      @extend .currency;
      .contentMapImage {
        width: 100%;
        height: 417px;
        > img {
          @extend .imgFill;
        }
      }
      .contactUs {
        padding-top: 40px;
        padding-bottom: 60px;
        display: flex;
        width: 1042px;
        margin: 0 auto;
        justify-content: space-between;
        .contactItem {
          display: flex;
          align-items: center;
          .contactItemImg {
            width: 52px;
            height: 52px;
            > img {
              @extend .imgFill;
            }
          }
          .contactItemEmail {
            padding-left: 18px;
            .itemEmail {
              padding-bottom: 16px;
              font-size: 16px;
              font-weight: 600;
              color: #0f4790;
              line-height: 24px;
            }
            .itemAdd {
              height: 24px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  .boxContentFooter {
    background: #00244f;
  }
}
</style>
