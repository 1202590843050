<template>
  <div class="footer">
    <div class="footerTop">
<!--      <div class="serviceSupport">-->
<!--        <div class="support">服务支持</div>-->
<!--        <div>咨询服务</div>-->
<!--        <div>运营服务</div>-->
<!--        <div>技术支持</div>-->
<!--        <div>培训教育</div>-->
<!--        <div class="other">其他</div>-->
<!--      </div>-->
      <div class="linkBank">
        <div class="link">友情链接</div>
        <div><a  class="link-partner" href="http://www.eximbank.gov.cn/" target="_blank">中国进出口银行</a></div>
        <div><a href="http://www.abchina.com/cn/" class="link-partner" target="_blank">中国农业银行</a></div>
        <div><a href="http://www.pbc.gov.cn/" class="link-partner" target="_blank">中国人民银行</a></div>
        <div  class="link-partner">中央证券公司</div>
      </div>
      <div class="contactMy">
        <div class="contact">联系我们</div>
        <div class="email">服务邮箱：service@topsoft.com.cn</div>
        <div>客服电话：010-83020210</div>
        <div class="address">公司地址：北京市海淀区学院南路62号中关村资本大厦11层</div>
      </div>
      <div class="officialWechat">
        <div class="wechat">官方微信</div>
        <div class="officialWechatImage">
          <img src="../../assets/homePageImg/bottom_qr_code@2x.png" alt="" />
        </div>
      </div>
    </div>
    <div class="stopLine"></div>
    <div class="versionNumber">
      <div>©2021 版权所有 华炫鼎盛（北京）科技有限公司</div>
      <br>
      <div><a class="bei-an" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备18045268号</a></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 100%;
  background: #00244f;
  color: rgba( 255, 255,255,0.5);
  .footerTop {
    display: flex;
    justify-content: space-around;
    .serviceSupport {
      padding-top: 80px;
      > div {
        padding-bottom: 20px;
        // width: 48px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        padding-right: 180px;
      }
      .support {
        color: rgba( 255, 255,255,0.8);
        padding-bottom: 30px;
        // width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        //
      }
      .other {
        padding-bottom: 43px;
      }
    }
    .linkBank {
      padding-top: 80px;
      > div {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 20px;

      }
      .link-partner{
        color: #fff;
        cursor: pointer;
        opacity: .5;
        &:hover{
          opacity: 1;
        }
      }
      .link {
        color: rgba( 255, 255,255,0.8);
        //
        padding-left: 0;
        // width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding-bottom: 30px;
      }
    }
    .contactMy {
      padding-top: 80px;
      > div {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        /*padding-left: 202px;*/
        padding-bottom: 20px;
      }
      .contact {
        /*padding-left: 202px;*/
        color: rgba( 255, 255,255,0.8);
        //
        // width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding-bottom: 30px;
      }
      .email {
        /*padding-left: 202px;*/
      }
      .address {
        // width: 313px;
        height: 17px;
      }
    }
    .officialWechat {
      padding-top: 80px;
      /*padding-left: 120px;*/
      .wechat {
        // width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba( 255, 255,255,0.6);
      }
      .officialWechatImage {
        padding-top: 39px;
        width: 97px;
        height: 97px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .stopLine {
    width: 1180px;
    height: 1px;
    opacity: 0.1;
    background: #FFFFFF;
  }
  .versionNumber {
    margin: 0 auto;
    padding-top: 30px;
    // width: 263px;
    height: 51px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    padding-bottom: 80px;
    .bei-an {
      font-size: 12px;
      color: #FFFFFF;
      line-height: 17px;
      opacity: .5;
    }
  }
}
</style>
